@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500&display=swap');

body {
  position: relative;
  background-image: url('assets/bg.jpg');
  background-size: cover;
  font-family: 'Poppins', sans-serif;
  overflow: hidden;
}
.App {
  position: relative;
}
.container {
  width: 1200px;
  margin: auto;
}
a {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
  color: #EAAF39!important;
  transition: 0.2s all;
}
.nav-container {
  width: 100%;
  height: 220px;
  text-align: center;
  background-color: #292423;
}
.content-container {
  position: relative;
  height: calc(100vh - 220px);
}
.content-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 5;
}
.nav {
  max-width: 1200px;
  z-index: 10;
  margin: auto;
  padding-top: 5px;
}
.nav-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo img {
  width: 240px;
}
.nav-list {
  display: flex;
}
.nav-list li {
  display: inline-block;
  list-style-type: none;
}
.nav-list a {
  position: relative;
  top: 4px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 20px;
  white-space: nowrap;
  font-family: 'Roboto', sans-serif;
}

.nav-list li button {
  position: relative;
  top: -3px;
  left: 15px;
  width: 165px;
  height: 40px;
  line-height: 35px;
  background-color: #EAAF39;
  border-radius: 3px;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.nav-list li button:hover {
  cursor: pointer;
  opacity: 0.9;
  transition: 0.2s all;
}

button {
  outline: none;
  border: none;
}
.circle-button {
  position: absolute;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #EAAF39;
  border-radius: 50%;
  z-index: 55;
}
.circle-button:hover {
  cursor: pointer;
  opacity: 0.9;
  transition: 0.2s all;
}
.plus-button {
  bottom: 80px;
}
.minus-button {
  bottom: 30px;
}

.nav-container h2 {
  position: relative;
  top: 10px;
  font-size: 40px;
  color: #EAAF39;
  margin-top: 0;
}

.nav-tabs {
  position: relative;
  top: -35px;
  display: flex;
}

.nav-tabs h6 {
  padding: 10px 25px;
  font-size: 16px;
  color: #EAAF39;
}
.active {
  border: 1px solid #474140;
  border-bottom: none;
}
.nav-tabs:hover .nav-tabs h6 {
  border: 1px solid #474140;
  border-bottom: none;
}
.nav-tabs h6:hover {
  cursor: pointer;
}

.reservation-modal {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  padding: 30px;
  background-color: #fff;
  color: #000;
  z-index: 25;
  border-radius: 12px;
}
.dark-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 21;
}
.open {
  display: block;
}

.reservation-modal > h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 35px;
}

.reservation-modal h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}

.reservation-time {
  display: flex;
  justify-content: space-between;
}
.reservation-time-left,
.reservation-time-right {
  width: 139px;
}
.reservation-time-right {
  margin-right: 30px;
}
.reservation-time div p {
  font-size: 14px;
  font-weight: 500;
}

.reservation-time div select:hover,
.reservation-time div select:focus {
  outline: none;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F2F7FA;
  border-radius: 10px;
}
.counter .minus {
  position: relative;
  left: 25px;
}
.counter .minus:hover,
.counter .plus:hover {
  cursor: pointer;
}
.counter input {
  width: 160px;
  height: 30px;
  text-align: center;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
.counter input:placeholder-shown {
  font-size: 20px;
  font-weight: 600;
  color: #000;
}
input:focus {
  outline: none;
}
.counter .plus {
  position: relative;
  right: 25px;
}
.reservation-modal h3 {
  font-size: 36px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 0;
}
.food-container {
  display: flex;
  max-height: 170px;
  overflow-y: auto;
  overflow-x: hidden;
}
.food-container span {
  display: block;
}
.food {
  margin-left: 40px;
}

.reservation-modal .submit-button {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: #EAAF39;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  margin-top: 40px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 2px;
}
.submit-button:hover {
  cursor: pointer;
}
.submit-button a {
  color: #fff!important;
  text-decoration: none;
}
.submit-button a:hover {
  color: #fff!important;
  text-decoration: none;
}

.tables-area{
  position: absolute;
  z-index: 10;
  width: 900px;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
/*
.table-area-container {
  position: relative;
  display: inline-block;
  margin: auto;
  width: auto;
  margin: auto;
  border: 1px solid green;
  height: 245px;
  min-width: 395px;
}
*/

.tables-area .table {

  position: relative;

}
.tables-area .table .table-number{
    position: absolute;
    text-align: center;
    color: #000;
    font-size: 12px;
}

.tables-area .table1 .table-number{
  left: 30px;
  top: 20px;
}

.tables-area .table2 .table-number{
        left: 20px;
        bottom: 15px;
    
}

.tables-area .table3 .table-number{
  right: 30px;
  top: 20px;
    
}
.tables-area .table4 .table-number{
        right: 20px;
        bottom: 15px;
    
}

.tables-area .table5 .table-number,
.tables-area .table6 .table-number,
.tables-area .table7 .table-number,
.tables-area .table8 .table-number {
        top: 0;
        left: 0;
        width: 100%;
        /*height: 100%; staro */
        height: 83%;
        display: flex;
        align-items: center;
        justify-content: center;
    
}




label {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.date-picker-field {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
}
.date-picker-field label {
  color: #212529;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 9px;
}
.date-picker-field > input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
  height: 38px;
  position: relative;
  text-align: left;
  padding: 0 15px;
  font-size: 20px;
  font-weight: 600;
  padding-left: 0;
}
.date-picker-field > input::after {
  content: ' ';
  display: block;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.date-picker-field .rdrCalendarWrapper {
  position: absolute;
  top: 85px;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.rdrCalendarWrapper{
  width: 300px !important;
}
.rdrDateDisplayWrapper{
  width: 100% !important;
}
.rdrWeekDays,
.rdrMonthAndYearWrapper {
  width: 100%!important;
}


.time-picker {
  position: relative;
  width: 146px;
}
.time-picker input {
  font-size: 20px;
  font-weight: 600;
  color: #EAAF39;
  border-bottom: 1px solid #EAAF39!important;
}
@media (max-width: 768px) {
  .time-picker {
    width: 100px;
 }
}
.time-picker input {
  width: 146px;
}
@media (max-width: 768px) {
  .time-picker input {
    width: 100px;
 }
}
.time-picker .dropdown {
  display: flex;
  position: absolute;
  top: 57px;
  left: 0;
  border-radius: 10px;
  background-color: #F2F7FA;
}
.time-picker .dropdown-close {
  display: none;
}
.time-picker .dropdown-item {
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  height: 230px;
  overflow-y: scroll;
  z-index: 999;
  background-color: #F2F7FA;
  padding: 5px;
  border-radius: 10px;
}
.time-picker .dropdown-item button {
  background-color: #F2F7FA;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  outline: none;
  border: none;
  height: 28px;
  width: 56px;
  padding: 0;
  text-align: center;
  font-size: 14px;
  margin-left: 0;
  font-weight: 400;
}
.time-picker .dropdown-item button:hover {
  background-color: #e6f7ff;
}
.time-picker .dropdown-item .active {
  background-color: #e6f7ff;
}
.time-picker ::-webkit-scrollbar-track {
  background: #e6edf5;
}


.time-picker input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #000;
  outline: none;
  height: 38px!important;
  position: relative;
  text-align: left;
  padding: 0 15px;
  font-size: 20px;
  font-weight: 600;
  padding-left: 0;
}

.help {
  color: #212529!important;
  font-size: 16px!important;
  font-weight: 500!important;
  margin-bottom: 9px!important;
  margin-top: 0;
  position: relative;
}


.form-group label {
  display: block;
  font-size: 14px;
  font-weight: 500;
}
.form-group input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #000;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  padding: 5px 0;
  margin-bottom: 15px;
}
.form-group input::placeholder {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.last-step {
  text-align: center;
}
.last-step img {
  margin-top: 40px;
}
.last-step h5 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0;
}
.last-step p {
  font-size: 12px;
  padding: 0 80px;
  margin-top: 5px;
}


.modal-header {
  display: flex;
  justify-content: space-between;
}
.modal-header h2 {
  margin: 0;
}
.modal-header .close-icon {
  font-size: 26px;
  font-weight: 600;
  position: relative;
  top: -5px;
}
.modal-header .close-icon:hover {
  cursor: pointer;
}






[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #000;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #EAAF39;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}



.checkbox-wrap-v2 {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.checkbox-wrap-v2 .checkbox-v2 {
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 52px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  border: 1px solid #ddd;
  background: #fff;
}
.checkbox-wrap-v2 .checkbox-v2::after {
  content: '';
  display: block;
  width: 0; 
  height: 0;
  border-bottom: 0 solid white;
  border-right: 0 solid white;
  transform: rotate(45deg);
}
.checkbox-wrap-v2 .checkbox-v2.checkbox-v2-checked {
}
.checkbox-wrap-v2 .checkbox-v2.checkbox-v2-checked::after {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  background-color: #EAAF39;
  border-radius: 52px;
}
.checkbox-wrap-v2.checkbox-wrap-v2-disabled .checkbox-v2 {
  background-color: #ddd;
}
.checkbox-wrap-v2.checkbox-wrap-v2-disabled span {
  color: #ddd;
}
.checkbox-text {
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: #000;
}

.drink > span,
.food > span {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

.food-container::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.food-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 10px;
}

/* Handle */
.food-container::-webkit-scrollbar-thumb {
  background: #EAAF39;
  border-radius: 10px;
}

.test {
  position: relative;
  z-index: 999;
  width: 100vw;
  height: calc(100vh - 220px);
}

.tools {
  position: relative;
  z-index: 100;
}

.react-transform-wrapper {
  z-index: 20;
}
.bars-container {
  display: none;
}
.bars {
  display: none;
}
.header-row {
  display: flex;
  justify-content: space-between;
}



@media (max-width: 768px) {
  body {
    overflow: unset!important;
  }
  .nav-container {
    height: 175px;
  }
  .content-container {
    height: calc(100vh - 175px);
  }
  .nav-list {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 9997;
    background-color: #292423;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    height: 100vh;
    margin-top: 8px !important;
    width: 100%;
    max-width: 100%;
    padding-left: 0;
  }
  .nav-list-shown {
    display: block;
  }
  .nav-list li {
    position: relative;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    padding-bottom: 12px;
  }
  .nav-list li:first-child {
    padding-top: 10px;
    margin-top: 15vh;
  }
  .nav-list li:last-child {
    padding-bottom: 25px;
  }
  .nav-list li a {
    padding-left: 20px;
    padding-right: 20px;
  }
  .nav-list li button {
    left: 0;
    top: 5px;
  }
  .logo img {
    width: 200px;
    margin-top: 18px;
    margin-left: 10px;
  }
  .bars-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 2px;
    position: absolute;
    top: 18px;
    right: 20px;
    background-color: #fff;
  }
  .bars {
    display: block;
    width: 21px;
  }
  .container {
    width: auto;
  }
  .nav-tabs {
    top: -15px;
  }
  .tables-area {
    left: unset;
    transform: unset;
  }
  .nav-container h2 {
    font-size: 22px;
    top: 15px;
  }
  .test {
    transform: scale(0.2);
  }
  .tables-area {
    left: -75%;
  }
}

@media (min-width: 700px) and (max-width: 768px) {
  .tables-area {
    left: -15%!important;
  }
  .nav-list li:first-child {
    margin-top: 30vh;
  }
}

@media (min-width: 1200px) and (max-height: 1000px) {
  .test {
    transform: scale(0.5);
  }
  .tables-area {
    top: -15%;
  }
}

@media (min-height: 1200px) and (max-height: 1350px) {
  .tables-area {
    top: 15%;
  }
}


.date-picker-field {
  margin-bottom: 0;
  flex-direction: unset;
}
.date-picker-field .rdrCalendarWrapper {
  z-index: 9999999!important;
  top: 45px;
  left: 45px;
}

.header-row .date-picker-field input {
  color: #EAAF39;
  border-bottom: 1px solid #EAAF39;
}

.reservation-time-left .time-picker input {
  color: #000;
  border-bottom: 1px solid #000!important;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .nav-tabs {
    top: -5px;
  }
  .nav-tabs h6 {
    font-size: 13px;
    padding: 10px 16px;
  }
  .header-row .date  {
    display: block;
    height: 30px!important;
    margin-top: 32px!important;
    position: relative;
    right: 30px;
  }
  .header-row .date input {
    font-size: 14px;
    width: 110px;
    height: auto;
  }
  .date-picker-field label {
    display: none;
  }
  .date-picker-field .rdrCalendarWrapper {
    position: fixed;
    top: 180px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}